
import firstUrlHandler from './firstUrlHandler';
import marketingUrlHandler from './marketingUrlHandler';

const DOM = {};
const endpoints = {
	'client': '/',
	'partners': '/'
};

const events = {
	'client': 'lead-form',
	'partners': 'partner-form'
};

const successCallbacks = {
	'client': _ => {
		window.location.href = '/download';
	}
}

const grecaptchaLoadingState = {
	isInProgress: false,
	loadingPromise: null
};

const userEvents = ['click', 'mousemove', 'scroll', 'focus', 'touchstart', 'input', 'keydown'];

const init = _ => {
	cacheDOM();
	bindEvents();
};

const cacheDOM = _ => {
	DOM.forms = [...document.querySelectorAll('[data-form]')];
};

const bindEvents = _ => {
	userEvents.forEach(evt => {
		document.addEventListener(evt, loadRecaptchaApiOnFirstEvent);
	})
	DOM.forms.forEach(form => {
		form.addEventListener('submit', handleSubmit);
		form.addEventListener('keydown', validate.bind(null, form));
		form.addEventListener('paste', validate.bind(null, form));
	});
	
};

const loadRecaptchaApiOnFirstEvent = _ => {
	loadRecaptchaApi();
	userEvents.forEach(evt => {
		document.removeEventListener(evt, loadRecaptchaApiOnFirstEvent);
	})
	

};

const loadRecaptchaApi = _ => {

	grecaptchaLoadingState.loadingPromise = new Promise(res => {
		grecaptchaLoadingState.isInProgress = true;

		const script = document.createElement('script');
		script.src = 'https://www.google.com/recaptcha/api.js?onload=setCaptcha&render=explicit';
		script.onload = _ =>  {
            grecaptchaLoadingState.isInProgress  = false;
			grecaptchaLoadingState.loadingPromise = null;
			res();
		}

		document.head.appendChild(script);

	});
}


const handleSubmit = async evt => {
	evt.preventDefault();
	const form = evt.target;
	const inputs = [...form.querySelectorAll('input')];
	inputs.forEach(input => input.blur());
	setTimeout(async _ => {
		const someInputIsInvalid = inputs.some(input => input.classList.contains('not-valid'));
		if (someInputIsInvalid) {
			validate(form);
			return;
		}
		form.classList.add('is-request');

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ event: events[form.dataset.form] });

		const grecaptchaLoadingPromise = getGrecaptchaLoadingPromise();
		
		await grecaptchaLoadingPromise;
		
		grecaptcha.ready(_ => {
			if (!grecaptcha.getResponse(form.grecaptchaWidgetId)) {
				grecaptcha.execute(form.grecaptchaWidgetId);
				return;
			}
			send(form);
		});
	});

};

const getGrecaptchaLoadingPromise = _ => {
	if (typeof grecaptcha !== 'undefined') {
		return new Promise(res => res());
	}
	if (!grecaptchaLoadingState.isInProgress) {
		loadRecaptchaApi();
	}
	return grecaptchaLoadingState.loadingPromise;
}

const send = async form => {
	const url = endpoints[form.dataset.form];
	const data = new FormData(form);
	data.append('page-url', firstUrlHandler.getUrl());
	data.append('marketing-link', marketingUrlHandler.getUrl());
	data.append('g-recaptcha-response', grecaptcha.getResponse(form.grecaptchaWidgetId));

	const json = getFormDataJson(data);
	const params = {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: json
	};

	const req = await fetch(url, params);
	await req.text();
	form.classList.remove('is-request');
	form.classList.add('on-success');
	form.reset();
	if (successCallbacks[form.dataset.form]) {
		successCallbacks[form.dataset.form]();
	}


};

const getFormDataJson = data => {
	return JSON.stringify([...data].reduce((acc, [key, val]) => {
		acc[key] = val;
		return acc;
	}, {}));
};

const validate = form => {
	setTimeout(_ => {
		const inputs = [...form.querySelectorAll('input[required]')];
		const everyInputIsValid = inputs.every(input => input.checkValidity() && input.classList.contains('is-valid'));
		const action = everyInputIsValid ? 'add' : 'remove';
		form.classList[action]('is-valid-form');
	}, 100)
};


window.setCaptcha = _ => {
	DOM.forms.forEach(form => {
		const captchaContainer = form.querySelector('[data-captcha-container]');
		form.grecaptchaWidgetId = grecaptcha.render(captchaContainer, {
			'sitekey': '6LfB-PQZAAAAAEXXCN5kDg00hFQ3DBhFfzaX_kFU',
			'size': 'invisible',
			'callback': send.bind(null, form),
			'expired-callback': grecaptcha.reset
		});
	});
};





export default {
	init: init
};