import tickersHandler from '../components/tickersHandler';
import videoMobileSrcHandler from '../components/videoMobileSrcHandler';
import mainVideoHandler from '../components/mainVideoHandler';
import linksToFormHandler from '../components/linksToFormHandler';
import tariffsNamesHandler from '../components/tariffsNamesHandler';
import mobileCTABtnHandler from '../components/mobileCTABtnHandler';
import desktopFixedFormHandler from '../components/desktopFixedFormHandler';
import dropdownsHandler from '../components/dropdownsHandler';
import formsHandler from '../components/formsHandler';
import validator from '../components/validator';
import phoneMasksHandler from '../components/phoneMasksHandler';




const modules = [
	tickersHandler,
	videoMobileSrcHandler,
	mainVideoHandler,
	linksToFormHandler,
	tariffsNamesHandler,
	mobileCTABtnHandler,
	desktopFixedFormHandler,
	dropdownsHandler,
	formsHandler,
	validator,
	phoneMasksHandler
];

document.addEventListener('DOMContentLoaded', _ => {
	modules.forEach(module => module.init());
});
