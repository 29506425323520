/**
* Модуль, инициализирующий бегущие строки
**/


import Swiper from 'swiper';
import SwiperCore, {
	Autoplay,
} from 'swiper/core';
import 'swiper/swiper.min.css';

SwiperCore.use([
	Autoplay,
]);


const tickers = [
	{
		key: 'services',
		selector: '.services__slider',
		config: {
			speed: 7200,
			loop: true,
			slidesPerView: 'auto',
			autoplay: {
				delay: 0,
			}
		}
	},
	{
		key: 'taxes',
		selector: '.taxes__slider',
		config: {

			speed: 6000,
			loop: true,
			slidesPerView: 'auto',
			autoplay: {
				delay: 0,
			}
		}

	},
	{
		key: 'about',
		selector: '.about_partnership__slider',
		config: {
			speed: 6000,
			loop: true,
			slidesPerView: 'auto',
			autoplay: {
				delay: 0,
			}
		}
	}

];


const init = _ => {
	window.addEventListener('sliderIsInView', ({detail}) =>  {
		const ticker = tickers.find(ticker => ticker.key === detail);
		const { selector, config } = ticker;
		const el = document.querySelector(selector);
		if (!el) return;
		new Swiper(el, config);
	})
};


export default {
	init: init
};