/**
* Модуль подменяющий источник видео на мобильных разрешениях
*/

const init = _ => {
	
	if (!window.matchMedia('(max-width: 768px)').matches) return;
	document.querySelectorAll('[data-mobile-src]').forEach(video =>  {
		video.src = video.dataset.mobileSrc;
		
	});
	document.addEventListener('touchstart', handleTouchStart);

};

const handleTouchStart = _ => {
	const video = document.querySelector('[data-mobile-src]').parentElement;
	video.load();
	video.play();
	document.removeEventListener('touchstart', handleTouchStart);

};



export default {
	init: init
};