/**
* Управление скроллом и фокусом нужных форм по нажатию связанных кнопок
**/


const DOM = {};

const init = _ =>  {
	cacheDOM();
	bindEvents();
};

const cacheDOM = _ => {
	DOM.triggers = [...document.querySelectorAll('[data-input-focus-to]')];
	DOM.inputs = [...document.querySelectorAll('[data-focus-input-id]')];
};

const bindEvents = _ => {
	const clickEvent = window.matchMedia('(max-width: 900px)').matches ? 'touchend' : 'click';
	DOM.triggers.forEach(trigger => {
		trigger.addEventListener(clickEvent, handleTriggerClick);
	});
};

const handleTriggerClick = evt => {
	evt.preventDefault();
	const id = evt.target.href.split('#')[1];
	focusInput(id);
};

const focusInput = id =>  {
	const input = DOM.inputs.find(input => input.dataset.focusInputId === id);
	input.focus();
	input.closest('section').scrollIntoView();
};

export default {
	init: init
};