import IMask from 'imask/esm/imask';
import 'imask/esm/masked/pattern';

const init = _ => {
	document.querySelectorAll('[type=tel]').forEach(input => {
		input.addEventListener('focus', createImask);
		input.addEventListener('blur', destroyImask);
	});
};

const createImask = evt => {
	const input = evt.target;

	input.mask = IMask(input, {
		mask: '+{7} (000) 000-00-00',
		lazy: true,
		country: 'Russia'
	});
};

const destroyImask = evt => {
	const input = evt.target;
	const mask = input.mask;
	mask.destroy();

	// if (mask && mask.unmaskedValue.length < 11 || mask.unmaskedValue === '+7 (___) ___-__-__') {
	// 	mask.destroy();
	// 	input.value = '';
	// }
};



export default {
	init: init
};