/**
* Модуль изменения текста в фиксированном блоке при скролле тарифов
**/

const DOM = {};

let lastIndex;

const bottomScrollOffset = 120;

const init = _ => {
	if (window.matchMedia('(max-width: 768px)').matches) return;
	cacheDOM();
	setObserver();

};



const cacheDOM = _ => {
	DOM.scrollSectionName = document.querySelector('[data-tariffs-scroll-name]');
	DOM.scrollSections = [...document.querySelectorAll('[data-scroll-section]')];
	DOM.scrollBlock = document.querySelector('.tariffs_scroll_block');
	DOM.fixedForm = document.querySelector('[data-fixed-form]');
};


const setObserver = _ => {
	const observer = createObserver();
	const { scrollSections } = DOM;
	DOM.scrollSections.forEach((section, index) => {
		section.dataset.scrollSection = index;
		lastIndex = index === scrollSections.length - 1 ? index : '';
		observer.observe(section);
	});
};

const intersectionHandler = entries => {
	const { scrollBlock, scrollSectionName, fixedForm } = DOM;
	entries.forEach(entry => {
		const { isIntersecting, boundingClientRect, target } = entry;

		if (!isIntersecting && boundingClientRect.bottom < bottomScrollOffset) {
			scrollBlock.classList.add('is-active');
			fixedForm.classList.add('is-active');
			scrollSectionName.textContent = target.textContent;

			if (scrollSectionName.textContent == '') {
				scrollSectionName.classList.add('is-off');
			} else {
				scrollSectionName.classList.remove('is-off');
			}
		}

		if (isIntersecting && boundingClientRect.bottom < bottomScrollOffset && target.dataset.scrollSection > 0) {
			const targetSection = document.querySelector(`[data-scroll-section="${target.dataset.scrollSection - 1}"]`);
			const text = targetSection.textContent;

			scrollSectionName.textContent = text;

			if (scrollSectionName.textContent == '') {
				scrollSectionName.classList.add('is-off');
			} else {
				scrollSectionName.classList.remove('is-off');
			}
		}

		if (isIntersecting && target.dataset.scrollSection == 0) {
			scrollBlock.classList.remove('is-active');
			fixedForm.classList.remove('is-active');
		}

		if (!isIntersecting && target.dataset.scrollSection == lastIndex && boundingClientRect.bottom < bottomScrollOffset) {
			scrollBlock.classList.remove('is-active');
			fixedForm.classList.remove('is-active');
		}

		if (isIntersecting && target.dataset.scrollSection == lastIndex) {
			scrollBlock.classList.add('is-active');
			fixedForm.classList.add('is-active');
		}
	});
};

const createObserver = _ => {
	return new IntersectionObserver(intersectionHandler, {
		threshold: .1,
		rootMargin: '20px 0% 0% 0%',
		root: null,
	});
};

export default {
	init: init
};