const DOM = {};


const init = _ =>  {
	if (window.matchMedia('(max-width: 768px)').matches) return;
	cacheDOM();
	setObserver();
};

const cacheDOM = _ => {
	DOM.fixedForm = document.querySelector('[data-fixed-form]');
	DOM.visiblityTriggers = document.querySelectorAll('[data-fixed-form-block-target]');
	DOM.tariffsScrollBlock = document.querySelector('.tariffs_scroll_block');
};

const setObserver = _ => {
	const { visiblityTriggers } = DOM;
	const observer = createObserver();
	visiblityTriggers.forEach(trigger =>  {
		observer.observe(trigger);
	});
};

const intersectionHandler = entries => {
	const { fixedForm, tariffsScrollBlock } = DOM;
	entries.forEach( entry => {

		if (entry.isIntersecting) {
			fixedForm.classList.remove('is-active');
		}
		else if (tariffsScrollBlock.classList.contains('is-active')) {
			fixedForm.classList.add('is-active');

		}
	});
};

const createObserver = _ => {
	return new IntersectionObserver(intersectionHandler, {  
		threshold: .1, 
		rootMargin: '0% 0% 0%',
		root: null,
	});
};


export default  {
	init: init
};