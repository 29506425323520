/**
* Модуль контроллирующий видимость мобильной CTA-кнопки
* Скрывает кнопку, если в зоне видимости есть форма
**/


const DOM = {};

const inputPositions = [];


const init = _ => {
	if (!window.matchMedia('(max-width: 768px)').matches) return;
	cacheDOM();
	if (isIOSSafari()) {
		setScrollHandler();
	}
	else {
		setObserver();
	}
};

const cacheDOM = _ => {
	DOM.ctaBtn = document.querySelector('[data-mobile-cta]');
	DOM.formInputs = document.querySelectorAll('[data-input-block]:not(.fixed_form_block .form_block)');
};

const setObserver = _ => {
	const { formInputs } = DOM;
	const observer = createObserver();
	formInputs.forEach(input => {
		observer.observe(input);
	});
};

const setScrollHandler = _ => {
	DOM.formInputs.forEach(el => {
		const rect = el.getBoundingClientRect();
		inputPositions.push([rect.top, rect.bottom]);
	});
	document.querySelectorAll('input').forEach(el => {
		el.addEventListener('focus', handleFocus);
		el.addEventListener('blur', handleBlur);
	});

	window.addEventListener('scroll', handleScroll);
};

const intersectionHandler = entries => {
	const { ctaBtn, formInputs } = DOM;
	entries.forEach(entry => entry.target.isIntersecting = entry.isIntersecting);
	const inputIsInView = [...formInputs].some(input => input.isIntersecting);
	const action = inputIsInView ? 'remove' : 'add';
	ctaBtn.classList[action]('is-active');
};

const handleScroll = _ => {
	const { ctaBtn } = DOM;
	const topIsInView = top => top > window.scrollY;
	const bottomIsInView = bottom => bottom < (window.scrollY + window.innerHeight);
	const inputIsInView = inputPositions.some(positions => topIsInView(positions[0]) && bottomIsInView(positions[1]));
	ctaBtn.classList.toggle('is-active', !inputIsInView);

};

const handleFocus = ({ target }) => {
	window.addEventListener('touchmove', handleScrollOnFocus.bind(null, window.scrollY, target));
};

const handleBlur = _ => {
	window.removeEventListener('touchmove', handleScrollOnFocus);
};

const handleScrollOnFocus = (prevScroll, input) => {
	setTimeout(_ => {
		if (Math.abs(window.scrollY - prevScroll) >= 200) {
			input.blur();
			window.removeEventListener('touchmove', handleScrollOnFocus);
		}

	}, 50);
};


const createObserver = _ => {
	return new IntersectionObserver(intersectionHandler);
};


const isIOSSafari = _ => {
	const ua = window.navigator.userAgent;
	const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
	const webkit = !!ua.match(/WebKit/i);
	return iOS && webkit && !ua.match(/CriOS/i);
};

export default {
	init: init
};