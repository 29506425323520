

const init = _ => {

	document.querySelectorAll('input').forEach(input => {
		input.addEventListener('keydown', validate);
		input.addEventListener('paste', validate);
		if (input.name === 'agent-email') {
			input.addEventListener('blur', validateEmail)
		}
	});
};

const validate = evt => {


	const input = evt.target;
	setTimeout(_ => {
		switch (input.type) {
			case 'tel':
				validatePhone(input);
				break;
			default:
				validateDefaultInput(input);
		}
	}, 20);



};

const validatePhone = input => {
	const pattern = /(\+7 \($)|(\+7 \(9.*)/;
	const phoneIsCorrect = pattern.test(input.value);
	const completedPattern = /\+7 \(9\d\d\) \d\d\d\-\d\d\-\d\d/;
	
	if (input.value && !phoneIsCorrect) {
		input.classList.add('not-valid');
		input.classList.remove('is-valid');

	}
	else {
		input.classList.remove('not-valid');
		if (input.value) {
			input.classList.add('is-valid');
		}
		else  {
			input.classList.remove('is-valid');
		}


	}
	if (input.value && completedPattern.test(input.value)) {
		input.classList.add('is-completed');
	}
	else  {
		input.classList.remove('is-completed');
	}

};

const validateDefaultInput = input => {
	if (input.value && !input.checkValidity()) {
		input.classList.add('not-valid');
		input.classList.remove('is-valid');
		input.classList.remove('is-completed');

	}
	else {
		input.classList.remove('not-valid');
		if (input.value) {
			input.classList.add('is-valid');
		}

	}

	if (input.value && input.checkValidity()) {
		input.classList.add('is-completed');
	}
};


const validateEmail = ({target}) => {
	const input = target;
	const pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
	

	setTimeout(_ => {
		if (input.value && pattern.test(input.value)) {
			input.classList.add('is-completed');
		}
		else if (input.value)  {
			
			input.classList.remove('is-completed');
			input.classList.remove('is-valid');
			input.classList.add('not-valid');
		}
	})

}

export default {
	init: init
};