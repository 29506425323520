const DOM = {};

const init = _ => {
	cacheDOM();
	bindEvents();
	DOM.blocks.forEach( block => {
		block.classList.remove('is-active');
	});
};

const cacheDOM = _ => {
	DOM.blocks = [...document.querySelectorAll('[data-dropdown-block]')];
	DOM.btns = [...document.querySelectorAll('[data-dropdown-btn]')];	
};

const bindEvents = _ => {
	const { btns } = DOM;
	btns.forEach(btn =>  {
		btn.addEventListener('click', handleClick);
	});
};

const handleClick = evt => {
	evt.preventDefault();
	evt.target.closest('[data-dropdown-block]').classList.toggle('is-active');
	document.dispatchEvent(new CustomEvent('heightChanged'));
};

export default {
	init: init
};