
const DOM = {};


const init = _ => {
	if (window.matchMedia('(max-width: 768px)').matches) return;
	cacheDOM();
	initObserver();
};

const cacheDOM = _ => {
	DOM.video = document.querySelector('.open_online__video video');
	DOM.videoBlock = document.querySelector('.open_online__video');
	DOM.triggerBlock = document.querySelector('.open_online_list__item--3');
};

const setIsViewVideoBlock = (mutationsList, observer) => {
	const { video, videoBlock } = DOM;

	video.load();

	videoBlock.classList.add('is-inview');

	video.play();

	observer.disconnect();
};
const initObserver = _ => {
	const observer = new MutationObserver(setIsViewVideoBlock);
	observer.observe(DOM.triggerBlock, { attributes: true });
};

export default {
	init: init
};